import { TableCell,  TableRow } from '@mui/material'

const TRow = ({row, selectedFields}) => (
  <TableRow>
    <TableCell >
      {row['Name']}
    </TableCell>
    {selectedFields.map((field) => (
      <TableCell key={field}>
        {row[field]}
      </TableCell>
    ))}
    <TableCell/>
  </TableRow>
)

export default TRow