import Typography  from '../../../components/Typography'
import { Button, TableCell, TableHead, TableRow } from '@mui/material'
import Select from '../../../components/Select'
import Flex from '../../../components/Flex'
import { FIELDS, useCS2Context } from './CS2Context'

const THeader = () =>  {
  const {addField, removeField, changeField, selectedFields} = useCS2Context()
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography fontWeight='bold'>
            Name
          </Typography>
        </TableCell>
        {selectedFields.map((field, i) => (
          <TableCell key={field}>
            <Flex>
              <Select
                value={field}
                options={FIELDS}
                onChange={({target}) => {
                  console.log(target.value)
                  changeField(i, target.value)
                }}
              />
              <Button onClick={() => removeField(i)}>x</Button>
            </Flex>
          </TableCell>
        ))}
        <TableCell>
          <Button onClick={addField}>Add</Button>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default THeader