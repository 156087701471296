import Box from '../../../components/Box'
import Typography  from '../../../components/Typography'
import Data from '../../../data/cs2/output.json'
import { Link, Table, TableBody } from '@mui/material'
import Flex from '../../../components/Flex'
import { CS2ContextProvider, useCS2Context } from './CS2Context'
import FiltersModal from './FiltersModal'
import THeader from './THeader'
import TRow from './TRow'
import Banner from './Banner'

const _CS2 = () => {
  const {
    selectedTypes, 
    selectedFields,
    sortOption,
    sortDirection,
  } = useCS2Context()
  const sortOrder = sortDirection === 'Ascending' ? 1 : -1
  return (
  <Box px={4}>
    <Flex justifyContent="center">
    <Banner/>
    </Flex>
    <Typography>
      Based off <Link href="OGDoc">CS2 Weapon Spreadsheet</Link> by BlackRetina & SlothSquadron 
    </Typography>
    <Typography>
      Web app built by EasyKill (SethIMcClaine)
    </Typography>
    <Typography>
      Last spreadsheet version update: 2024-05-23
    </Typography>
    <FiltersModal/>
    {selectedTypes.map((type) => (
      <Box key={type} maxWidth='80%'>
        <Typography variant='h2'>{type}</Typography>
        <Table>
          <THeader/>
          <TableBody>
            {Data[type]
              .sort((a,b) => sortOrder * (a[sortOption] < b[sortOption] ? -1 : 1))
              .map((row, i) => (
              <TRow selectedFields={selectedFields} row={row} key={i}/>
            )
            )}
          </TableBody> 
        </Table>
      </Box>
    ))} 
  </Box>
  )
}

const CS2 = () => (
  <CS2ContextProvider>
    <_CS2/>
  </CS2ContextProvider>
)

export default CS2