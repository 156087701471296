import BannerImg from '../../../assets/cs2/cs2Banner.jpg'

const Banner = () =>  (      
  <img 
    src={BannerImg} 
    alt="CounterStrike 2" 
    style={{ width: '95vw' }}
  />
)

export default Banner