import { Select as MUISelect, MenuItem } from "@mui/material"
const Select = ({options, ...props}) => {
  return (
    <MUISelect
      {...props}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
    </MUISelect>
  )
}

export default Select