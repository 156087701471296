import { createContext, useState, useContext } from "react"
import Data from '../../../data/cs2/output.json'

export const TYPES = Object.keys(Data)
export const FIELDS = Object.keys(Data[TYPES[0]][0]).filter((v) => v !== 'Name')
export const SORT_DIRECTIONS = ['Ascending', 'Descending']

const CS2Context = createContext(null)

export const CS2ContextProvider = ({children}) => {
  const [selectedFields, setSelectedFields] = useState(FIELDS)
  const [selectedTypes, setSelectedTypes] = useState(Object.keys(Data))
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTIONS[1])
  const [sortOption, setSortOption] = useState('Damage')
  const addField = () =>  setSelectedFields([...selectedFields, 'Name'])
  const removeField = (index) =>  setSelectedFields(selectedFields.splice(index, 1))
  const changeField = (index, value) =>  {
    const tmp = [...selectedFields]
    tmp[index] = value //will this work?
    setSelectedFields(tmp)
  }

  const toggleSelectedTypes = (type) => {
    if(selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type))
    } else {
      setSelectedTypes([...selectedTypes, type])
    }
  }

  return (
    <CS2Context.Provider
      value={{
        Data,
        selectedFields,
        selectedTypes, // setSelectedFields,
        addField,
        removeField,
        changeField,
        toggleSelectedTypes, // setSelectedTypes,
        sortOption,
        setSortOption,
        sortDirection,
        setSortDirection,
      }}
    >
      {children}
    </CS2Context.Provider>
  )
}

export const useCS2Context = () => useContext(CS2Context)
