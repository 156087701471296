import { useCS2Context, TYPES, FIELDS, SORT_DIRECTIONS } from "./CS2Context"
import { Checkbox } from '@mui/material'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Typography  from '../../../components/Typography'
import Select from "../../../components/Select"

const FiltersModal = () => {
  const {
    sortOption,
    setSortOption,
    sortDirection,
    setSortDirection,
    selectedTypes, 
    toggleSelectedTypes
  } = useCS2Context()

  return (
    <Flex p={2} style={{
      borderRadius: 20,
      backgroundColor:'black',
      justifyContent: 'space-around'
    }}>
      <Box>
        <Typography variant="h2">
          Sort
        </Typography>
        <Select
          value={sortOption}
          options={FIELDS}
          onChange={({target}) => setSortOption(target.value)}
        />
        <Select
          value={sortDirection}
          options={SORT_DIRECTIONS}
          onChange={({target}) => setSortDirection(target.value)}
        />
      </Box>
      <Box>
        <Typography variant="h2">
          Show
        </Typography>
        <Flex>
          {TYPES.map((type) => (
            <Flex key={type} alignItems="center" p={1}>
              <Checkbox 
                style={{borderRadius: 2, height: 22, width: 22}}
                checked={selectedTypes.includes(type)} 
                onClick={() => toggleSelectedTypes(type)}/>
              <Typography >&nbsp;{type}</Typography>
            </Flex>
          ))}
        </Flex>
      </Box>


    </Flex>
  )
}

export default FiltersModal