import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import TeapotRacing from "./pages/TeapotRacing";
import Projects from './pages/Projects'
import CS2 from './pages/Projects/CS2'
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const routes = [{
  name: 'Home',
  path: '/',
  element: <Home/>,
}, {
  name: 'TeapotRacing',
  path: '/racing',
  element: <TeapotRacing/>,
}, {
//@TODO
//   hidden: true,
//   name: 'Projects',
//   path: '/projects',
//   element: <Projects/>,
// }, {
  hidden: true, // @TODO
  name: 'CS2',
  path: '/projects/cs2',
  element: <CS2/>,
}];

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Header routes={routes}/>
        <Routes>
          {routes.map(({name, path, element}) => (
            <Route key={`$route-${name}`} path={path} element={element} />
          ))}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
